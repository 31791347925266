<template>
  <div class="feedback">
    <h2>请选择投诉原因</h2>
    <van-radio-group v-model="radio" class="list">
      <van-cell-group>
        <van-cell
          :title="item.title"
          clickable
          @click="radio = item.radio"
          v-for="(item, index) in listData"
          :key="index"
        >
          <van-radio :name="item.radio">
            <template #icon="props">
              <van-image
                :src="props.checked ? activeIcon : inactiveIcon"
                :show-loading="false"
                width="4.3vw"
                height="4.3vw"
              />
            </template>
          </van-radio>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <div class="bottom-btn" @click="gofeedback">提交举报</div>
  </div>
</template>

<script>
import { Image as VanImage, Radio, RadioGroup, Cell, CellGroup } from 'vant';

import { showLoading, hideLoading, showToast } from '@/utils/VantUtils';

import { CLOSE_WEBVIEW } from '@/utils/Agreement';

export default {
  components: {
    VanImage,
    VanRadio: Radio,
    VanRadioGroup: RadioGroup,
    VanCell: Cell,
    VanCellGroup: CellGroup
  },
  data() {
    return {
      radio: '1',
      activeIcon: require('@/assets/images/icon/check.png'),
      inactiveIcon: require('@/assets/images/icon/check-none.png'),
      listData: [
        {
          title: '欺诈',
          radio: '1'
        },
        {
          title: '色情',
          radio: '2'
        },
        {
          title: '诱导行为',
          radio: '3'
        },
        {
          title: '不实信息',
          radio: '4'
        },
        {
          title: '违法犯罪',
          radio: '5'
        },
        {
          title: '骚扰',
          radio: '6'
        },
        {
          title: '内容未经授权/滥用原创',
          radio: '7'
        },
        {
          title: '其他',
          radio: '8'
        }
      ]
    };
  },
  methods: {
    gofeedback() {
      showLoading();
      setTimeout(() => {
        hideLoading();
        showToast('提交成功');
        setTimeout(() => {
          window.location.href = CLOSE_WEBVIEW;
        }, 300);
      }, 1200);
    }
  }
};
</script>

<style lang="less" scoped>
.feedback {
  background: #f8f8f8;
  padding-top: 4px;

  h2 {
    background: #fff;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    padding: 24px 18px 14px;
  }
  .list {
    .van-cell {
      padding: 12px 18px;
      align-items: center;
      justify-content: space-between;
      overflow: visible;
      .van-cell__value {
        flex: unset;
        overflow: visible;
      }
    }
  }
  .bottom-btn {
    margin: 0 auto;
    margin-top: 36px;
    width: 343px;
    height: 44px;
    opacity: 1;
    background: linear-gradient(180deg, #b0ffee 0%, #46c6ad 100%);
    border-radius: 22px;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 44px;
  }
}
</style>
